import { useTranslation } from 'react-i18next';
import { KPIsInterface } from '@o4c/dip-shared';
import * as Converter from '../../../services/Converter';
import HistoryChart from '../../shared/historyChart/HistoryChart';

function HistoryWeek(props: { kpis: KPIsInterface[] }) {
  const { t } = useTranslation();
  const goalsData = Converter.getGoalsPerDay(props.kpis, 'ccc HH:mm', t);
  const gallonsPerHourData = Converter.getGallonsPerHourPerDay(props.kpis, 'ccc HH:mm');
  const gallonsDeliveredData = Converter.getGallonsDeliveredPerDay(props.kpis, 'ccc HH:mm');
  const completedRouteTimeData = Converter.getCompleteRouteTimePerDay(props.kpis, 'ccc HH:mm');

  const goalsChartProps = { id: "history-week-goals", data: goalsData, height: 150, yTicks: 3, rotate: 90, showTarget: false, targetExceededPositive: true, margin: { top: 20, right: 20, bottom: 60, left: 30 } };
  const gallonsPerHourChartProps = { id: "history-week-gallons-per-hour", data: gallonsPerHourData, height: 240, yTicks: 6, rotate: 90, showTarget: true, targetExceededPositive: true, margin: { top: 20, right: 20, bottom: 60, left: 40 } };
  const gallonsDeliveredChartProps = { id: "history-week-gallons-delivered", data: gallonsDeliveredData, height: 200, yTicks: 5, rotate: 90, showTarget: true, targetExceededPositive: true, margin: { top: 20, right: 20, bottom: 60, left: 40 } };
  const completedRouteTimeChartProps = { id: "history-week-completed-route-time", data: completedRouteTimeData, height: 240, yTicks: 5, rotate: 90, showTarget: true, targetExceededPositive: false, margin: { top: 20, right: 20, bottom: 60, left: 40 } };

  return <>
    <HistoryChart title={t('titles.goals')} chartProps={goalsChartProps} />
    <HistoryChart title={t('titles.gallonsPerHour')} chartProps={gallonsPerHourChartProps} />
    <HistoryChart title={t('titles.gallonsDelivered')} chartProps={gallonsDeliveredChartProps} />
    <HistoryChart title={t('titles.completedRouteTime')} chartProps={completedRouteTimeChartProps} />
  </>
}
export default HistoryWeek;