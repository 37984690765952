import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import { PeriodType } from '../../sections/history/Periods';
import Config from '../../../services/Config';
import './PeriodDatePicker.scss';
import en from 'date-fns/locale/en-US';
import nl from 'date-fns/locale/nl';
registerLocale('nl', nl);
registerLocale('en', en);

export interface PeriodDatePickerProps {
  locale: "en" | "nl";
  value: { from: string, to: string };
  periodType: PeriodType;
  onSelect: (from: string, to: string) => void;
}

function PeriodDatePicker(props: PeriodDatePickerProps) {
  const handleChange = (date: Date) => {
    const dt = DateTime.fromJSDate(date);
    if (props.periodType === "day") {
      props.onSelect(dt.toFormat('yyyy-MM-dd'), dt.toFormat('yyyy-MM-dd'))
    } else if (props.periodType === "week") {
      props.onSelect(dt.startOf("week").toFormat('yyyy-MM-dd'), dt.endOf("week").toFormat('yyyy-MM-dd'))
    } else if (props.periodType === "month") {
      props.onSelect(dt.toFormat('yyyy-MM-dd'), dt.endOf("month").toFormat('yyyy-MM-dd'))
    } else if (props.periodType === "year") {
      props.onSelect(dt.toFormat('yyyy-MM-dd'), dt.endOf("year").toFormat('yyyy-MM-dd'))
    }
  }

  const handleWeekSelect = (firstDayOfWeek: Date, weekNumber: number, event: any) => {
    if (firstDayOfWeek <= new Date() && props.periodType === "week") {
      const fromDt = DateTime.fromJSDate(firstDayOfWeek);
      const toDt = fromDt.plus({ days: 6 });
      props.onSelect(fromDt.toFormat('yyyy-MM-dd'), toDt.toFormat('yyyy-MM-dd'));
    }
  }

  return <DatePicker
    locale={props.locale}
    selected={new Date(props.value.from)}
    startDate={new Date(props.value.from)}
    endDate={new Date(props.value.to)}
    minDate={new Date(Config.historyMinDate)}
    maxDate={new Date()}
    calendarClassName={`period-date-picker select-${props.periodType}-mode`}
    showWeekNumbers={props.periodType === "week"}
    showMonthYearPicker={props.periodType === "month"}
    showYearPicker={props.periodType === "year"}
    onWeekSelect={handleWeekSelect}
    onChange={handleChange}
    calendarStartDay={1}
    inline
  />;
}
export default PeriodDatePicker;