import { OrderKPIInterface } from "@o4c/dip-shared";
import RadialProgressBar from "../../../shared/radialProgressBar/RadialProgressBar";
import "./GallonsDeliveredPerOrder.scss";
import { useEffect } from "react";

function GallonsDeliveredPerOrder(props: { orders: OrderKPIInterface[] }) {
    return (
        <div className="gallons-delivered-per-order">
            {props.orders.map((order, i) => {

				const { customerName } = order;

				const trimCustomerName = (_name: string) => {
					if(_name.length > 20) {
						return `${_name.slice(0, 16)}...`
					}

					return _name
				}

                return (
                    <div
                        key={`gallons-delivered-${order.orderNumber}`}
                        className="card gallons-delivered-order"
                    >
                        <div className="gallons-delivered-order-number">{trimCustomerName(customerName)}</div>
                        <RadialProgressBar
                            id={`gallons-delivered-card-${order.orderNumber}`}
                            completed={false}
                            thickness={6}
                            //orderedQty should be the plannedQty, 
                            //but we receive the plannedQty value on the orderedQty field.
                            expected={order.orderedQty}
                            actual={order.deliveredQty}
                        />
                    </div>
                );
            })}
        </div>
    );
}
export default GallonsDeliveredPerOrder;
