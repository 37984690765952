import { useEffect } from 'react';
import * as d3 from 'd3';
import './TargetBarChart.scss';

export interface TargetBarChartDataRow {
  date: string;
  actual: number;
  expected: number;
}

export interface TargetBarChartProps {
  id: string;
  data: TargetBarChartDataRow[];
  yTicks: number;
  rotate?: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number; }
  targetExceededPositive?: boolean;
  showTarget: boolean;
}

function TargetBarChart(props: TargetBarChartProps) {
  useEffect(() => {
    function targetBarChart() {
      const parent = d3.select(`#${props.id}`);
      parent.html("");
      const domRect = parent.node() ? (parent.node() as any).getBoundingClientRect() : 100;
      const domRectWidth = domRect.width > 1000 ? 1000 : domRect.width;
      const margin = props.margin ? props.margin : { top: 20, right: 20, bottom: 70, left: 60 };
      const width = domRectWidth - margin.left - margin.right;
      const height = props.height - margin.top - margin.bottom;

      const x: any = d3.scaleBand().rangeRound([0, width]);
      const y: any = d3.scaleLinear().range([height, 0]);

      const xAxis = d3.axisBottom(x).ticks(props.data.length);
      const yAxis = d3.axisLeft(y).ticks(props.yTicks);

      const svg = parent.append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");

      x.domain(props.data.map((d) => { return d.date; }));
      if (props.targetExceededPositive !== undefined) {
        y.domain([0, d3.max(props.data, (d) => { return d.actual >= d.expected ? d.actual : d.expected; })]);
      } else {
        y.domain([0, d3.max(props.data, (d) => { return d.actual; })]);
      }

      const xLabel = svg.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .selectAll("text")

      if (props.rotate !== undefined) {
        xLabel.style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", "-.55em")
          .attr("transform", `rotate(-${props.rotate})`);
      }


      svg.append("g")
        .attr("class", "y axis")
        .call(yAxis)

      svg.append("g")
        .attr("class", "x-grid")
        .attr("transform", `translate(${x.bandwidth() / 2},${height})`)
        .call(xAxis.tickSize(-height).tickFormat("" as any))

      svg.append("g")
        .attr("class", "y-grid")
        .call(yAxis.tickSize(-width).tickFormat("" as any))

      const rx = 5;

      if (props.showTarget) {
        svg.selectAll("bar")
          .data(props.data)
          .enter().append("rect")
          .attr("class", "bar-expected")
          .attr("x", (d) => { return x(d.date); })
          .attr("width", x.bandwidth())
          .attr("y", (d) => { return y(d.expected); })
          .attr("height", (d) => { return height - y(d.expected); });
      }

      const getBarCssClass = (d: TargetBarChartDataRow) => {
        if (props.targetExceededPositive === undefined) {
          return 'bar';
        } else {
          if (props.targetExceededPositive) {
            return d.actual >= d.expected ? 'bar-positive' : 'bar-negative';
          } else {
            return d.actual <= d.expected ? 'bar-positive' : 'bar-negative';
          }
        }
      }

      svg.selectAll("bar")
        .data(props.data)
        .enter().append("rect")
        .attr("class", getBarCssClass)
        .attr("x", (d) => { return x(d.date) + x.bandwidth() / 4; })
        .attr("rx", rx)
        .attr("ry", rx)
        .attr("width", x.bandwidth() / 2)
        .attr("y", (d) => { return y(d.actual); })
        .attr("height", (d) => { return height - y(d.actual); });

      svg.selectAll("bar")
        .data(props.data)
        .enter().append("rect")
        .attr("class", getBarCssClass)
        .attr("x", (d) => { return x(d.date) + x.bandwidth() / 4; })
        .attr("rx", 0)
        .attr("ry", 0)
        .attr("width", x.bandwidth() / 2)
        .attr("y", (d) => { return d.actual === 0 ? 0 : height - rx })
        .attr("height", (d) => { return d.actual === 0 ? 0 : rx });

      if (props.showTarget) {
        svg.selectAll("bar")
          .data(props.data)
          .enter().append("line")
          .attr("class", "line-expected")
          .attr("x1", (d) => { return x(d.date); })
          .attr("x2", (d) => { return x(d.date) + x.bandwidth(); })
          .attr("y1", (d) => { return y(d.expected); })
          .attr("y2", (d) => { return y(d.expected); })
          .attr("width", x.bandwidth())
          .attr("height", (d) => { return height - y(d.expected); });
      }
    }
    targetBarChart();
    window.addEventListener("resize", targetBarChart);
    return () => window.removeEventListener("resize", targetBarChart);
  }, [props])
  return <div id={props.id} className="target-bar-chart"></div>;
}
export default TargetBarChart;