import './GallonsDeliveredCompleted.scss';
import { GoalInterface } from '../../../interfaces/GoalInterface';
import Card from '../../layout/card/Card';
import GoalHeader from '../goalHeader/GoalHeader';
import RadialProgressBar from '../radialProgressBar/RadialProgressBar';
import RadialChartContainer from '../radialChartContainer/RadialChartContainer';

function GallonsDeliveredCompleted(props: { id: string, goal: GoalInterface, planned: number, delivered: number }) {
  return <Card>
    <div className="gallons-delivered-completed">
      <GoalHeader goal={props.goal} />
      <RadialChartContainer>
        <RadialProgressBar id={props.id} completed={props.goal.completed} thickness={15} expected={props.planned} actual={props.delivered} />
      </RadialChartContainer>
    </div>
  </Card>;
}
export default GallonsDeliveredCompleted;