import { NavLink } from 'react-router-dom';
import './Tabs.scss';

interface TabsProps {
  items: { to: string; title: string; }[];
}

function Tabs(props: TabsProps) {
  return <div className="tabs">
    {
      props.items.map((item, i) => <NavLink key={`tab-${i}`} to={item.to} className="tab-item" activeClassName="active">{item.title}</NavLink>)
    }
  </div>;
}
export default Tabs;