import { SelectOption } from '../../../interfaces/SelectOption';
import './ButtonSelectList.scss';

interface ButtonSelectListProps {
  items: SelectOption[];
  value: string;
  onSelect: (value: string) => void;
}

function ButtonSelectList(props: ButtonSelectListProps) {
  return <div className="button-select-list">
    {
      props.items.map((item, i) => <button className={item.value === props.value ? "selected" : ""} key={`button-select-list-item-${item.value}`} onClick={() => { props.onSelect(item.value) }} >{item.label}</button>)
    }
  </div>;
}
export default ButtonSelectList;