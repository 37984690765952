import './CompletedRouteTime.scss';
import { GoalInterface } from '../../../interfaces/GoalInterface';
import Card from '../../layout/card/Card';
import GoalHeader from '../goalHeader/GoalHeader';
import RadialChartContainer from '../radialChartContainer/RadialChartContainer';
import RadialCountdownBar, { RadialCountdownBarProps } from '../radialCountdownBar/RadialCountdownBar';

function CompletedRouteTime(props: { goal: GoalInterface, chartData: RadialCountdownBarProps }) {
  return <Card>
    <div className="completed-route-time">
      <GoalHeader goal={props.goal} />
      <RadialChartContainer>
        <RadialCountdownBar {...props.chartData} />
      </RadialChartContainer>
    </div>
  </Card>;
}
export default CompletedRouteTime;