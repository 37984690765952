import './Today.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Api from '../../../services/Api';
import { KPIsInterface } from '@o4c/dip-shared';
import TodayCompleted from './TodayCompleted';
import TodayInProgress from './TodayInProgress';
import Loader from '../../layout/loader/Loader';
import { DateTime } from 'luxon';
import  jwt_decode  from 'jwt-decode';
import Message from '../../layout/message/Message';
import { AxiosError } from 'axios';

function Today() {
  let location = useLocation();
  let query = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const token = query.get("token") || "";
  const decodedToken: any = jwt_decode(token);
  const firstName = decodedToken ? decodedToken.firstName : "";

  const [kpis, setKpis] = useState<KPIsInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorType, setErrorType] = useState<"error" | "warning">("warning");

  useEffect(() => {
    async function loadKPIs() {
      if (token) {
        try {
          const today = DateTime.now().toFormat('yyyy-MM-dd');
          const { data } = await Api.getKPIs(token, { fromDate: today, toDate: today });
          //If data is empty backend returns 404.
          if (data.length === 1) {
            setKpis(data[0]);
          } else if (data.length > 0) {
            const pastTrips = data.filter((o) => { return o.startTime && DateTime.fromISO(o.startTime) <= DateTime.now() }).sort((a, b) => {
              if(a.startTime !== null && b.startTime !== null) {
                if (DateTime.fromISO(a.startTime) < DateTime.fromISO(b.startTime)) return 1;
                if (DateTime.fromISO(a.startTime) > DateTime.fromISO(b.startTime)) return -1;
              }
              return 0;
            });
            if (pastTrips.length > 0) {
              setKpis(pastTrips[0]);
            } else {
              const futureTrips = data.filter((o) => { return DateTime.fromISO(o.scheduledStartTime) > DateTime.now() }).sort((a, b) => {
                if (DateTime.fromISO(a.scheduledStartTime) < DateTime.fromISO(b.scheduledStartTime)) return -1;
                if (DateTime.fromISO(a.scheduledStartTime) > DateTime.fromISO(b.scheduledStartTime)) return 1;
                return 0;
              });
              if (futureTrips.length > 0) {
                setKpis(futureTrips[0]);
              }
            }
          }
          setError(false);
          setErrorMessage("");
        } catch (err) {
          if(err instanceof AxiosError && err?.response?.data.status === 404) {
            setError(true);
            setErrorType("warning");
            setErrorMessage(t('messages.noTrips'));
            
          } else {
            setError(true);
            setErrorType("error");
            setErrorMessage(t('messages.kpisError'));
          }
        }
      } else {
        setError(true);
        setErrorType("error");
        setErrorMessage(t('messages.noToken'));
      }
      setLoading(false);
    }
    loadKPIs();
  }, [token]);

  if (loading) {
    return <Loader visible={loading} />
  }

  if (!loading && error) {
    return <Message type={errorType} text={errorMessage} />
  }

  if (kpis) {
    if (kpis.isReconciled) {
      return <TodayCompleted kpis={kpis} firstName={firstName} />
    } else {
      return <TodayInProgress kpis={kpis} firstName={firstName} />
    }
  }
  return null;
}
export default Today;