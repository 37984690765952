import { ThemeInterface } from '../interfaces/ThemeInterface';

export const OrtecTheme: ThemeInterface = {
  name: 'ortec',
  properties: {
     //global
    'primary-color': '#222222',
    'accent-color': '#a90073',
    'grey-dark': '#6b6b6b',
    'grey-light': '#d6d6d6',
    'grey-x-light': '#efefef',
    'grey-xx-light': '#f6f6f6',
    'white': '#ffffff',
    'negative': '#db0000',
    'negative-d20': '#af0000',
    'alert': '#f07000',
    'alert-d20': '#c05a00',
    'positive': '#008738',
    'positive-d20': '#006c2d',
    'primary-base': '#0071b3',
    'primary-d40': '#00446b',
    'primary-d10': '#0066a1',
    'primary-d20': '#0e6492',
    'primary-l40': '#66aad1',

    'font-family': 'Roboto',
    'font-weight-normal': '400',
    'font-weight-medium': '500',
    'font-weight-bold': '700',
    'font-size-small': '0.9em',
    'font-size-normal': '1em',
    'font-size-medium': '1.2em',
    'font-size-large': '1.3em',
    'font-size-xlarge': '1.7em',
    'font-size-xxlarge': '2em',
    
    //components colors
    'tab-color': 'var(--grey-dark)',
    'tab-bg-color': 'var(--grey-x-light)',
    'tab-active-color': 'var(--accent-color)',
    'tab-active-bg-color': 'var(--white)',
    'tab-font-weight': 'var(--font-weight-bold)',
    'tab-border-color': 'rgba(0, 0, 0, 0.2)',
    'tab-active-border-color': 'var(--accent-color)',
    'top-area-color': 'var(--primary-color)',
    'top-area-bg-color': 'var(--white)',

    'card-bg-color': 'var(--white)',
    'card-border-color': 'var(--grey-light)',

    'card-title-color': 'var(--primary-color)',
    'card-title-font-weight': 'var(--font-weight-bold)',
    'card-title-font-size': 'var(--font-size-large)',

    'greeting-color': 'var(--grey-dark)',
    'greeting-font-size': 'var(--font-size-small)',
    'greeting-font-weight': 'var(--font-weight-bold)',

    'greeting-name-color': 'var(--primary-color)',
    'greeting-name-font-size': 'var(--font-size-xxlarge)',
    'greeting-name-font-weight': 'var(--font-weight-medium)',

    'goal-outline-border-color': 'var(--grey-light)',
    'goal-outline-bg-color': 'var(--white)',
    'goal-outline-positive-border-color': 'var(--positive)',
    'goal-outline-positive-bg-color': 'var(--white)',
    'goal-positive-border-color': 'var(--positive-d20)',
    'goal-positive-bg-color': 'var(--positive)',
    'goal-outline-negative-border-color': 'var(--negative)',
    'goal-outline-negative-bg-color': 'var(--white)',
    'goal-negative-border-color': 'var(--negative-d20)',
    'goal-negative-bg-color': 'var(--negative)',

    'today-goal-title-color': 'var(--grey-dark)',
    'today-goal-title-font-size': 'var(--font-size-normal)',
    'today-goal-title-font-weight': 'var(--font-weight-bold)',

    'bsl-bg': 'var(--grey-x-light)',
    'bsl-border-color': 'var(--white)',
    'bsl-color': 'var(--grey-dark)',
    'bsl-font-size': 'var(--font-size-normal)',
    'bsl-font-weight': 'var(--font-weight-bold)',
    'bsl-selected-bg': 'var(--primary-base)',
    'bsl-selected-border-color': 'var(--primary-base)',
    'bsl-selected-color': 'var(--white)',

    'psl-bg': 'var(--white)',
    'psl-disabled-bg': 'var(--grey-x-light)',
    'psl-border-color': 'var(--grey-light)',
    'psl-font-size': 'var(--font-size-medium)',
    'psl-hover-bg': 'var(--grey-x-light)',
    'psl-selected-bg': 'var(--primary-base)',
    'psl-selected-color': 'var(--white)',

    'bar-chart-color': 'var(--primary-base)'
  }
};