import { OrderKPIInterface, isSelfFuel } from "@o4c/dip-shared";
import { DateTime } from "luxon";

export const getPercentage = (partial: number, total: number): number => {
    return total === 0 ? 0 : (partial * 100) / total;
};

export const getGreeting = (t: any) => {
    const currentHour = DateTime.now().toObject().hour || 0;
    if (currentHour < 12) {
        return t("greetings.goodmorning");
    } else if (currentHour < 17) {
        return t("greetings.goodafternoon");
    } else {
        return t("greetings.goodevening");
    }
};

export const roundToTenth = (n: number): number => {
    return Math.round(n * 10) / 10;
};


export const filterOutSelfFuel = (
    orders: OrderKPIInterface[],
    truckCode: string
) => {
    return orders.filter((order) => !isSelfFuel(order.customer, truckCode));
};
