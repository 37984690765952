import Card from "../../layout/card/Card";
import CardTitle from "../../layout/cardTitle/CardTitle";
import TargetBarChart, { TargetBarChartProps } from "../targetBarChart/TargetBarChart";

interface HistoryChartProps {
  title: string;
  chartProps: TargetBarChartProps;
}

function HistoryChart(props: HistoryChartProps) {
  return <Card>
    <CardTitle title={props.title} />
    <TargetBarChart {...props.chartProps} />
  </Card>
}
export default HistoryChart;