import { GoalInterface } from '../../../interfaces/GoalInterface';
import Card from '../../layout/card/Card';
import GallonsPerHourBar from '../gallonsPerHourBar/GallonsPerHourBar';
import GoalHeader from '../goalHeader/GoalHeader';
import './GallonsPerHour.scss';

function GallonsPerHour(props: { goal: GoalInterface, percentage: number, amountPerHour: number }) {
  return <Card>
    <GoalHeader goal={props.goal} />
    <GallonsPerHourBar percentage={props.percentage} amountPerHour={props.amountPerHour} />
  </Card>;
}
export default GallonsPerHour;