import { OrderKPIInterface } from '@o4c/dip-shared';
import { GoalInterface } from '../../../../interfaces/GoalInterface';
import { DataRowInterface } from '../../../../interfaces/DataRowInterface';
import Card from '../../../layout/card/Card';
import GallonsDeliveredPerOrder from '../gallonsDeliveredPerOrder/GallonsDeliveredPerOrder';
import GoalHeader from '../../../shared/goalHeader/GoalHeader';
import TwoColumnTable from '../../../layout/twoColumnTable/TwoColumnTable';
import './GallonsDelivered.scss';

function GallonsDelivered(props: { goal: GoalInterface, balanceRows: DataRowInterface[], totalRows: DataRowInterface[], progressBarItems: { category: string; percentage: number; }[], orders: OrderKPIInterface[] }) {
  return <Card>
    <GoalHeader goal={props.goal} />
    <GallonsDeliveredPerOrder orders={props.orders} />
    <TwoColumnTable rows={props.balanceRows} />
    <TwoColumnTable rows={props.totalRows} />
  </Card>;
}
export default GallonsDelivered;