import * as d3 from 'd3';
import { useEffect } from 'react';
import './RadialCountdownBar.scss';

export interface RadialCountdownBarProps {
  id: string;
  thickness: number;
  subTextPadding: number;
  completed: boolean;
  left: number;
  plannedDuration: number;
  balance: number;
  text: string;
  subText: string;
}

function RadialCountdownBar(props: RadialCountdownBarProps) {
  useEffect(() => {
    function radialCountdownBar() {
      const parent = d3.select(`#${props.id}`);
      parent.html("");
      const domRect = parent.node() ? (parent.node() as any).getBoundingClientRect() : 100;
      const size = domRect.width > domRect.height ? domRect.height : domRect.width;
      const width = size;
      const height = size;

      const svg = parent.append('svg')
        .attr('width', width)
        .attr('height', height);

      const outerRadius = Math.min(width, height) * 0.45;
      const bgCssClass = 'rcb-bg';
      const progressCssClass = 'rcb-progress-bg';
      const balanceCssClass = props.balance > 0 ? 'rcb-positive-balance-bg' : 'rcb-negative-balance-bg';
      const shadowFilterId = `svg-filter-${props.id}`;

      const mainArc: any = d3.arc()
        .startAngle(0)
        .endAngle(Math.PI * 2)
        .innerRadius(outerRadius - props.thickness)
        .outerRadius(outerRadius);

      svg.append("filter")
        .attr('id', shadowFilterId)
        .html(`<feComponentTransfer in=SourceAlpha>
        <feFuncA type="table" tableValues="1 0" />
      </feComponentTransfer>
      <feGaussianBlur stdDeviation="4"/>
      <feOffset dx="0" dy="5" result="offsetblur"/>
      <feFlood flood-color="rgba(0, 0, 0, 0.3)" result="color"/>
      <feComposite in2="offsetblur" operator="in"/>
      <feComposite in2="SourceAlpha" operator="in" />
      <feMerge>
        <feMergeNode in="SourceGraphic" />
        <feMergeNode />
      </feMerge>`);

      svg.append("path")
        .attr('class', bgCssClass)
        .attr('filter', `url(#${shadowFilterId}`)
        .attr('transform', `translate(${width / 2},${height / 2})`)
        .attr('d', mainArc());

      const mainArcPath = svg.append("path")
        .attr('class', progressCssClass)
        .attr('transform', `translate(${width / 2},${height / 2})`);

      let end;
      if (!props.completed) {
        end = svg.append("circle")
          .attr('class', progressCssClass)
          .attr('transform', `translate(${width / 2},${height / 2 - outerRadius + props.thickness / 2})`)
          .attr('width', props.thickness)
          .attr('height', props.thickness)
          .attr('r', props.thickness / 2);
      }

      const balancePath = svg.append("path")
        .attr('class', balanceCssClass)
        .attr('transform', `translate(${width / 2},${height / 2})`);

      const balanceEnd = svg.append("circle")
        .attr('class', balanceCssClass)
        .attr('transform', `translate(${width / 2},${height / 2 - outerRadius + props.thickness / 2})`)
        .attr('width', props.thickness)
        .attr('height', props.thickness)
        .attr('r', props.thickness / 2);

      const textCssClass = props.completed ? 'rcb-text-label completed' : 'rcb-text-label';
      const subtextCssClass = props.completed ? 'rcb-subtext-label completed' : 'rcb-subtext-label';

      svg.append("text")
        .attr('class', textCssClass)
        .attr('transform', `translate(${width / 2},${height / 2})`)
        .text(props.text);

      svg.append("text")
        .attr('class', subtextCssClass)
        .attr('transform', `translate(${width / 2},${height / 2 + props.subTextPadding})`)
        .text(props.subText);

      const percentage = props.plannedDuration > 0 ? (100 * props.left) / props.plannedDuration : 0;
      const startValue = 100;
      const startAngle = Math.PI * startValue / 50;
      const angleDiff = Math.PI * percentage / 50 - startAngle;
      const startAngleDeg = startAngle / Math.PI * 180;
      const angleDiffDeg = angleDiff / Math.PI * 180;
      const transitionDuration = 2000;

      mainArcPath.transition().duration(transitionDuration).attrTween('d', function () {
        return function (t: any) {
          mainArc.endAngle(startAngle + angleDiff * t)
          return mainArc();
        }
      });

      if (!props.completed && end) {
        end.transition().duration(transitionDuration).attrTween('transform', function () {
          return function (t: any) {
            return `translate(${width / 2},${height / 2})` +
              `rotate(${(startAngleDeg + angleDiffDeg * t)})` +
              `translate(0,-${outerRadius - props.thickness / 2})`
          }
        });
      }

      const balancePercentage = (100 * Math.abs(props.balance)) / props.plannedDuration;
      const balanceStartValue = 0;
      const balanceStartAngle = Math.PI * balanceStartValue / 50;
      const balanceAngleDiff = Math.PI * balancePercentage / 50 - balanceStartAngle;
      const balanceStartAngleDeg = balanceStartAngle / Math.PI * 180;
      const balanceAngleDiffDeg = balanceAngleDiff / Math.PI * 180;
      const endAngle = props.balance < 0 ? balanceStartAngle + balanceAngleDiff : -(balanceStartAngle + balanceAngleDiff);
      const rotateEndAngle = props.balance < 0 ? balanceStartAngleDeg + balanceAngleDiffDeg : -(balanceStartAngleDeg + balanceAngleDiffDeg);

      balancePath.transition().duration(transitionDuration).attrTween('d', function () {
        return function (t: any) {
          mainArc.endAngle(endAngle * t)
          return mainArc();
        }
      });
      balanceEnd.transition().duration(transitionDuration).attrTween('transform', function () {
        return function (t: any) {
          return `translate(${width / 2},${height / 2})` +
            `rotate(${(rotateEndAngle * t)})` +
            `translate(0,-${outerRadius - props.thickness / 2})`
        }
      });
    }
    radialCountdownBar();
    window.addEventListener("resize", radialCountdownBar);
    return () => window.removeEventListener("resize", radialCountdownBar);
  }, [props]);

  return <div id={props.id} className="rcb"></div>;
}
export default RadialCountdownBar;