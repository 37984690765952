import { useTranslation } from 'react-i18next';
import { KPIsInterface } from '@o4c/dip-shared';
import { GoalInterface } from '../../../interfaces/GoalInterface';
import * as Converter from '../../../services/Converter';
import * as Utils from '../../../services/Utils';
import CompletedRouteTime from "../../shared/completedRouteTime/CompletedRouteTime";
import GallonsDeliveredCompleted from "../../shared/gallonsDeliveredCompleted/GallonsDeliveredCompleted";
import GallonsPerHour from "../../shared/gallonsPerHour/GallonsPerHour";
import GoalsProgress from "../../shared/goalsProgress/GoalsProgress";
import Greeting from "./greeting/Greeting";
import MainArea from "../../layout/mainArea/MainArea";
import TopArea from "../../layout/topArea/TopArea";

function TodayCompleted(props: { kpis: KPIsInterface, firstName: string }) {
  const { t } = useTranslation();
  const goals: GoalInterface[] = Converter.getGoals(props.kpis, t);
  const gphPercentage = Utils.getPercentage(props.kpis.gallonsPerHour.amountPerHour, props.kpis.gallonsPerHour.plannedAmountPerHour);
  const completedRouteTimeChartData = Converter.getCompletedRouteTimeChartData(props.kpis);
  const greeting = Utils.getGreeting(t);

  return <>
    <TopArea>
      <div className="today-top-area">
        <Greeting name={props.firstName} greeting={greeting} />
        <GoalsProgress goals={goals} containerClassName="today-goals-progress" />
      </div>
    </TopArea>
    <MainArea>
      <GallonsPerHour goal={goals[0]} percentage={gphPercentage} amountPerHour={props.kpis.gallonsPerHour.amountPerHour} />
      <div className="flex-gap">
        <div className="flex-gap-item">
          <GallonsDeliveredCompleted id="gallons-delivered-completed-today" goal={goals[1]} planned={props.kpis.gallonsDelivered.planned} delivered={props.kpis.gallonsDelivered.delivered} />
        </div>
        <div className="flex-gap-item">
          <CompletedRouteTime goal={goals[2]} chartData={completedRouteTimeChartData} />
        </div>
      </div>
    </MainArea>
  </>;
}
export default TodayCompleted;