import { PeriodSelectOption } from "../../layout/periodSelectList/PeriodSelectList";
import { DateTime } from 'luxon';

export type PeriodType = 'day' | 'week' | 'month' | 'year';

export const get = (t: any): { [key in PeriodType]: PeriodSelectOption[] } => {
  return {
    day: [
      { from: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'), to: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'), label: t('customPeriods.yesterday') },
    ],
    week: [
      { from: DateTime.now().startOf("week").toFormat('yyyy-MM-dd'), to: DateTime.now().endOf("week").toFormat('yyyy-MM-dd'), label: t('customPeriods.thisWeek') },
      { from: DateTime.now().minus({ weeks: 1 }).startOf("week").toFormat('yyyy-MM-dd'), to: DateTime.now().minus({ weeks: 1 }).endOf("week").toFormat('yyyy-MM-dd'), label: t('customPeriods.lastWeek') },
    ],
    month: [
      { from: DateTime.now().startOf("month").toFormat('yyyy-MM-dd'), to: DateTime.now().endOf("month").toFormat('yyyy-MM-dd'), label: t('customPeriods.thisMonth') },
      { from: DateTime.now().minus({ months: 1 }).startOf("month").toFormat('yyyy-MM-dd'), to: DateTime.now().minus({ months: 1 }).endOf("month").toFormat('yyyy-MM-dd'), label: t('customPeriods.lastMonth') },
    ],
    year: [
      { from: DateTime.now().startOf("year").toFormat('yyyy-MM-dd'), to: DateTime.now().endOf("year").toFormat('yyyy-MM-dd'), label: t('customPeriods.thisYear') },
      { from: DateTime.now().minus({ years: 1 }).startOf("year").toFormat('yyyy-MM-dd'), to: DateTime.now().minus({ years: 1 }).endOf("year").toFormat('yyyy-MM-dd'), label: t('customPeriods.lastYear') },
    ],
  };
}
