import { useTranslation } from 'react-i18next';
import { KPIsInterface } from '@o4c/dip-shared';
import * as Converter from '../../../services/Converter';
import HistoryChart from '../../shared/historyChart/HistoryChart';

function HistoryYear(props: { kpis: KPIsInterface[] }) {
  const { t } = useTranslation();
  const goalsData = Converter.getGoalsPerDay(props.kpis, 'LLL', t);
  const gallonsPerHourData = Converter.getGallonsPerHourGoalPerDay(props.kpis, 'LLL');
  const gallonsDeliveredData = Converter.getGallonsDeliveredGoalPerDay(props.kpis, 'LLL');
  const completedRouteTimeData = Converter.getCompletedRouteTimeGoalPerDay(props.kpis, 'LLL');

  const goalsChartProps = { id: "history-year-goals", data: Converter.getChartDataPerMonth(goalsData), height: 150, yTicks: 3, showTarget: false, margin: { top: 20, right: 20, bottom: 20, left: 20 } };
  const gallonsPerHourChartProps = { id: "history-year-gallons-per-hour", data: Converter.getChartDataPerMonth(gallonsPerHourData), height: 280, yTicks: 6, showTarget: false, margin: { top: 20, right: 20, bottom: 20, left: 40 } };
  const gallonsDeliveredChartProps = { id: "history-year-gallons-delivered", data: Converter.getChartDataPerMonth(gallonsDeliveredData), height: 240, yTicks: 5, showTarget: false, margin: { top: 20, right: 20, bottom: 20, left: 40 } };
  const completedRouteTimeChartProps = { id: "history-year-completed-route-time", data: Converter.getChartDataPerMonth(completedRouteTimeData), height: 280, yTicks: 5, showTarget: false, margin: { top: 20, right: 20, bottom: 20, left: 20 } };

  return <>
    <HistoryChart title={t('titles.allGoalsAchieved')} chartProps={goalsChartProps} />
    <HistoryChart title={t('titles.gallonsPerHourGoalsAchieved')} chartProps={gallonsPerHourChartProps} />
    <HistoryChart title={t('titles.gallonsDeliveredGoalAchieved')} chartProps={gallonsDeliveredChartProps} />
    <HistoryChart title={t('titles.completedRouteTimeGoalAchieved')} chartProps={completedRouteTimeChartProps} />
  </>
}
export default HistoryYear;