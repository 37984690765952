import { DataRowInterface } from '../../../interfaces/DataRowInterface';
import './TwoColumnTable.scss';

function TwoColumnTable(props: { rows: DataRowInterface[] }) {
  return <div>
    {
      props.rows.map((row, i) => <div key={`two-column-row-${i}`} className={`two-column-row ${row.variant}-text`}>
        <div className="two-column-row-label">{row.label}</div>
        <div className="two-column-row-value">{row.value}</div>
      </div>)
    }
  </div>
}
export default TwoColumnTable;