import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { KPIsInterface } from '@o4c/dip-shared';
import * as Api from '../../../services/Api';
import ButtonSelectList from '../../layout/buttonSelectList/ButtonSelectList';
import TopArea from '../../layout/topArea/TopArea';
import { PeriodType } from './Periods';
import * as Periods from './Periods';
import PeriodSelectList, { PeriodSelectOption } from '../../layout/periodSelectList/PeriodSelectList';
import Loader from '../../layout/loader/Loader';
import MainArea from '../../layout/mainArea/MainArea';
import HistoryDay from './HistoryDay';
import HistoryWeek from './HistoryWeek';
import HistoryMonth from './HistoryMonth';
import HistoryYear from './HistoryYear';
import Message from '../../layout/message/Message';
import { AxiosError } from 'axios';

function History() {
  let query = new URLSearchParams(useLocation().search);
  const { t, i18n } = useTranslation();
  const token = query.get("token") || "";
  const periods = Periods.get(t);
  //TODO - maybe save in localStorage last selected period type and period
  const yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd');

  const [periodType, setPeriodType] = useState<PeriodType>("day");
  const [selectedPeriods, setSelectedPeriods] = useState<PeriodSelectOption[]>(periods.day);
  const [fromDate, setFromDate] = useState<string>(yesterday);
  const [toDate, setToDate] = useState<string>(yesterday);
  const [loading, setLoading] = useState<boolean>(true);
  const [kpis, setKpis] = useState<KPIsInterface[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorType, setErrorType] = useState<"error" | "warning">("warning");

  useEffect(() => {
    setSelectedPeriods(periods[periodType]);
    setFromDate(periods[periodType][0].from);
    setToDate(periods[periodType][0].to);
  }, [periodType])

  useEffect(() => {
    async function loadKPIs() {
      if (token) {
        try {
          const { data } = await Api.getKPIs(token, { fromDate, toDate });
          setKpis(data);
          setError(false);
          setErrorMessage("");
        } catch (err) {
          setKpis([]);
          if(err instanceof AxiosError && err?.response?.data.status === 404) {
            setError(true);
            setErrorType("warning");
            setErrorMessage(t('messages.noTrips'));
            
          } else {
            setError(true);
            setErrorType("error");
            setErrorMessage(t('messages.kpisError'));
          }
        }
      } else {
        setKpis([]);
        setError(true);
        setErrorType("error");
        setErrorMessage(t('messages.noToken'));
      }
      setLoading(false);
    }
    loadKPIs();
  }, [token, fromDate, toDate]);

  const periodTypeItems = [
    { value: "day", label: t('periods.day') },
    { value: "week", label: t('periods.week') },
    { value: "month", label: t('periods.month') },
    { value: "year", label: t('periods.year') }
  ];

  const handlePeriodSelect = (from: string, to: string) => {
    setFromDate(from);
    setToDate(to);
  }

  return <>
    <TopArea>
      <ButtonSelectList items={periodTypeItems} value={periodType} onSelect={(value) => { setLoading(true); setPeriodType(value as PeriodType) }} />
      <PeriodSelectList locale={i18n.language as "en" | "nl"} value={{ from: fromDate, to: toDate }} periodType={periodType} items={selectedPeriods} onSelect={handlePeriodSelect} />
    </TopArea>
    <MainArea>
      <Loader visible={loading} />
      {!loading && error && <div className="fadein"><Message type={errorType} text={errorMessage} /></div>}
      {!loading && kpis.length > 0 && <div className="fadein">
        {periodType === "day" && kpis.map((row, i) => <HistoryDay key={`history-day-${i}`} kpis={row} />)}
        {periodType === "week" && <HistoryWeek kpis={kpis} />}
        {periodType === "month" && <HistoryMonth kpis={kpis} />}
        {periodType === "year" && <HistoryYear kpis={kpis} />}
      </div>
      }
    </MainArea>
  </>;
}
export default History;