import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import translation_en from "../locales/en/translation.json";
import translation_nl from "../locales/nl/translation.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translation_en
      },
      nl: {
        translation: translation_nl
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: true
    },
  });

export default i18n;