import * as d3 from 'd3';
import { useEffect } from 'react';
import './RadialProgressBar.scss';

export interface RadialProgressBarProps {
  id: string;
  thickness: number;
  completed: boolean;
  expected: number;
  actual: number;
}

function RadialProgressBar(props: RadialProgressBarProps) {

  useEffect(() => {
    function radialProgressBar() {
      const parent = d3.select(`#${props.id}`);
      parent.html("");
      const domRect = parent.node() ? (parent.node() as any).getBoundingClientRect() : 100;
      const size = domRect.width > domRect.height ? domRect.height : domRect.width;
      const width = size;
      const height = size;

      const svg = parent.append('svg')
        .attr('width', width)
        .attr('height', height);
      const outerRadius = Math.min(width, height) * 0.45;

      const initialPercentage = props.expected > 0 ? (props.actual * 100) / props.expected : 0;
      const bgCssClass = initialPercentage < 100 ? 'progress-bar-bg-le100' : 'progress-bar-bg-gt100';
      const progressCssClass = initialPercentage <= 100 ? 'progress-bar-le100' : 'progress-bar-gt100';

      const mainArc: any = d3.arc()
        .startAngle(0)
        .endAngle(Math.PI * 2)
        .innerRadius(outerRadius - props.thickness)
        .outerRadius(outerRadius);

      svg.append("path")
        .attr('class', bgCssClass)
        .attr('transform', `translate(${width / 2},${height / 2})`)
        .attr('d', mainArc());

      const mainArcPath = svg.append("path")
        .attr('class', progressCssClass)
        .attr('transform', `translate(${width / 2},${height / 2})`);

      if (initialPercentage <= 100) {
        svg.append("circle")
          .attr('class', progressCssClass)
          .attr('transform', `translate(${width / 2},${height / 2 - outerRadius + props.thickness / 2})`)
          .attr('width', props.thickness)
          .attr('height', props.thickness)
          .attr('r', props.thickness / 2);
      }

      const end = svg.append("circle")
        .attr('class', progressCssClass)
        .attr('transform', `translate(${width / 2},${height / 2 - outerRadius + props.thickness / 2})`)
        .attr('width', props.thickness)
        .attr('height', props.thickness)
        .attr('r', props.thickness / 2);

      const actualLabelCssClass = props.completed ? 'actual-label completed' : 'actual-label';
      const expectedLabelCssClass = props.completed ? 'expected-label completed' : 'expected-label';

      svg.append("text")
        .attr('class', actualLabelCssClass)
        .attr('transform', `translate(${width / 2},${height / 2})`)
        .text(Math.round(props.actual));

      const widthOffSet = props.completed ? 15 : 7;
      const heightOffset = props.completed ? 20 : 10;

      svg.append("text")
        .attr('class', expectedLabelCssClass)
        .attr('transform', `translate(${width / 2 - widthOffSet},${height / 2 + heightOffset})`)
        .text(Math.round(props.expected));

      const percentage = initialPercentage > 100 ? initialPercentage - 100 : initialPercentage;
      const startValue = 0;
      const startAngle = Math.PI * startValue / 50;
      const angleDiff = Math.PI * percentage / 50 - startAngle;
      const startAngleDeg = startAngle / Math.PI * 180;
      const angleDiffDeg = angleDiff / Math.PI * 180;
      const transitionDuration = 2000;

      mainArcPath.transition().duration(transitionDuration).attrTween('d', function () {
        return function (t: any) {
          mainArc.endAngle(startAngle + angleDiff * t)
          return mainArc();
        }
      });
      end.transition().duration(transitionDuration).attrTween('transform', function () {
        return function (t: any) {
          return `translate(${width / 2},${height / 2})` +
            `rotate(${(startAngleDeg + angleDiffDeg * t)})` +
            `translate(0,-${outerRadius - props.thickness / 2})`
        }
      });
    }
    radialProgressBar();
    window.addEventListener("resize", radialProgressBar);
    return () => window.removeEventListener("resize", radialProgressBar);
  }, [props]);

  return <div id={props.id} className="radial-progress-bar"></div>;
}
export default RadialProgressBar;