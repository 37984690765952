import { DateTime, Duration } from 'luxon';
import * as Utils from './Utils';
import { KPIsInterface, OrderKPIInterface } from '@o4c/dip-shared';
import { GoalInterface } from "../interfaces/GoalInterface";
import { DataRowInterface } from '../interfaces/DataRowInterface';
import { RadialCountdownBarProps } from '../components/shared/radialCountdownBar/RadialCountdownBar';
import { TargetBarChartDataRow } from '../components/shared/targetBarChart/TargetBarChart';

export const getGoals = (kpis: KPIsInterface, t: any): GoalInterface[] => {
  const completed = kpis.isReconciled;
  const started = (kpis.gallonsDelivered?.orders || []).length > 0;
  const completedRouteTimeLabel = completed ? t('titles.completedRouteTime') : t('titles.hoursToCompleteRoute');

  return [
    { name: t('titles.gallonsPerHour'), completed, successful: getGallonsPerHourSuccessful(kpis), started },
    { name: t('titles.gallonsDelivered'), completed, successful: getGallonsDeliveredSuccessful(kpis), started },
    { name: completedRouteTimeLabel, completed, successful: getCompletedRouteTimeSuccessful(kpis), started }
  ];
}

export const getGallonsDeliveredData = (kpis: KPIsInterface): { balanceRows: DataRowInterface[], totalRows: DataRowInterface[], progressBarItems: { category: string, percentage: number }[], orders: OrderKPIInterface[] } => {
  return {
    balanceRows: getBalanceRows(kpis),
    totalRows: [{ label: 'projected total', value: Math.round(kpis.gallonsDelivered.projectedTotal).toString(), variant: 'secondary' }],
    progressBarItems: getProgressBarItems(kpis),
    orders: kpis.gallonsDelivered.orders
  }
}

export const getCompletedRouteTimeChartData = (kpis: KPIsInterface): RadialCountdownBarProps => {
  const plannedDuration = getHours(kpis.completedRouteTime.plannedDuration);
  const estimated = getHours(kpis.completedRouteTime.estimated);
  const planned = getHours(kpis.completedRouteTime.planned);
  const balance = estimated - planned;
  const totalEstimated = plannedDuration + balance;

  return { id: `completed-route-time-countdown-${kpis.tripCode}`, thickness: 15, subTextPadding: 20, completed: true, left: 0, plannedDuration, balance, text: getHourFormat(kpis.gallonsPerHour.hours), subText: getHourFormat(plannedDuration) };
}

export const getHoursToCompleteRouteData = (kpis: KPIsInterface, t: any): { rows: DataRowInterface[], chartData: RadialCountdownBarProps } => {
  const plannedDuration = getHours(kpis.completedRouteTime.plannedDuration);
  const estimated = getHours(kpis.completedRouteTime.estimated);
  const planned = getHours(kpis.completedRouteTime.planned);
  const balance = estimated - planned;
  const projectedTotal = plannedDuration + balance;
  const left = plannedDuration - estimated; // TODO - check if kpis negative duration format should be fixed or consider sending numbers
  const subText = left >= 0 ? "LEFT" : "OVERDUE";
  return {
    rows: [
      { label: t('labels.planned'), value: getHourFormat(planned), variant: 'secondary' },
      { label: t('labels.estimated'), value: getHourFormat(estimated), variant: 'primary' },
      { label: t('labels.balance'), value: `${balance > 0 ? '+' : ''}${getHourFormat(balance)}`, variant: balance <= 0 ? 'positive' : 'negative' },
      { label: t('labels.totalEstimated'), value: getHourFormat(projectedTotal), variant: 'secondary' }
    ],
    chartData: { id: "hours-to-complete-countdown", thickness: 15, subTextPadding: 15, completed: false, left, plannedDuration, balance, text: getHourFormat(left, true), subText }
  }
}

export const getGoalsPerDay = (kpis: KPIsInterface[], dateFormat: string, t: any): TargetBarChartDataRow[] => {
  return kpis.map((row) => {
    const goals = getGoals(row, t);
    return {
      date: DateTime.fromISO(row.scheduledStartTime).toFormat(dateFormat),
      actual: goals.filter((g) => { return g.successful; }).length,
      expected: 3
    }
  })
}

export const getGallonsPerHourPerDay = (kpis: KPIsInterface[], dateFormat: string): TargetBarChartDataRow[] => {
  return kpis.map((row) => {
    return {
      date: DateTime.fromISO(row.scheduledStartTime).toFormat(dateFormat),
      actual: row.gallonsPerHour.amountPerHour,
      expected: row.gallonsPerHour.plannedAmountPerHour
    }
  })
}

export const getGallonsDeliveredPerDay = (kpis: KPIsInterface[], dateFormat: string): TargetBarChartDataRow[] => {
  return kpis.map((row) => {
    return {
      date: DateTime.fromISO(row.scheduledStartTime).toFormat(dateFormat),
      actual: row.gallonsDelivered.delivered,
      expected: row.gallonsDelivered.planned
    }
  })
}

export const getCompleteRouteTimePerDay = (kpis: KPIsInterface[], dateFormat: string): TargetBarChartDataRow[] => {
  return kpis.map((row) => {
    return {
      date: DateTime.fromISO(row.scheduledStartTime).toFormat(dateFormat),
      actual: getHours(row.completedRouteTime?.estimated),
      expected: getHours(row.completedRouteTime?.planned)
    }
  })
}

export const getChartDataPerMonth = (chartData: TargetBarChartDataRow[]): TargetBarChartDataRow[] => {
  let chartDataPerMonthObj: { [key: string]: TargetBarChartDataRow[]; } = {};

  chartData.forEach((row) => {
    if (!chartDataPerMonthObj[row.date]) chartDataPerMonthObj[row.date] = [];
    chartDataPerMonthObj[row.date].push(row);
  });

  const chartDataPerMonth = [];

  for (const month in chartDataPerMonthObj) {
    chartDataPerMonth.push({
      date: month,
      actual: chartDataPerMonthObj[month].reduce((prev, curr) => { return prev + curr.actual }, 0),
      expected: chartDataPerMonthObj[month].reduce((prev, curr) => { return prev + curr.expected }, 0),
    })
  }

  return chartDataPerMonth;
}

export const getGallonsPerHourGoalPerDay = (kpis: KPIsInterface[], dateFormat: string): TargetBarChartDataRow[] => {
  return kpis.map((row) => {
    return {
      date: DateTime.fromISO(row.scheduledStartTime).toFormat(dateFormat),
      actual: getGallonsPerHourSuccessful(row) ? 1 : 0,
      expected: 1
    }
  })
}

export const getGallonsDeliveredGoalPerDay = (kpis: KPIsInterface[], dateFormat: string): TargetBarChartDataRow[] => {
  return kpis.map((row) => {
    return {
      date: DateTime.fromISO(row.scheduledStartTime).toFormat(dateFormat),
      actual: getGallonsDeliveredSuccessful(row) ? 1 : 0,
      expected: 1
    }
  })
}

export const getCompletedRouteTimeGoalPerDay = (kpis: KPIsInterface[], dateFormat: string): TargetBarChartDataRow[] => {
  return kpis.map((row) => {
    return {
      date: DateTime.fromISO(row.scheduledStartTime).toFormat(dateFormat),
      actual: getCompletedRouteTimeSuccessful(row) ? 1 : 0,
      expected: 1
    }
  })
}

function getGallonsPerHourSuccessful(kpis: KPIsInterface): boolean {
  return kpis.gallonsPerHour.amountPerHour >= kpis.gallonsPerHour.plannedAmountPerHour;
}

function getGallonsDeliveredSuccessful(kpis: KPIsInterface): boolean {
  return kpis.gallonsDelivered.balance >= 0;
}

function getCompletedRouteTimeSuccessful(kpis: KPIsInterface): boolean {
  return DateTime.fromISO(kpis.completedRouteTime?.estimated) <= DateTime.fromISO(kpis.completedRouteTime?.planned);
}

function getHours(duration: string): number {
  if (!duration || duration === "" || duration === "Invalid Duration") return 0;
  const items = duration.split(":").map((i) => { return parseInt(i); });
  const [hours, minutes, seconds] = items;
  const totalHours = Math.abs(hours) + Math.abs(minutes) / 60 + Math.abs(seconds) / 3600;
  return hours > 0 ? totalHours : -totalHours;
}

function getHourFormat(hours: number, abs?: boolean): string {
  if (Number.isNaN(hours)) return "00:00";
  const hourFormat = Duration.fromObject({ hours: Math.abs(hours) }).toFormat('hh:mm');
  if (abs) {
    return hourFormat;
  }
  return hours > 0 ? hourFormat : `-${hourFormat}`;
}

function getBalanceRows(kpis: KPIsInterface): DataRowInterface[] {
  const balance = Utils.roundToTenth(Math.round(kpis.gallonsDelivered.balance));
  return [
    { label: 'planned', value: Math.round(kpis.gallonsDelivered.planned).toString(), variant: 'secondary' },
    { label: 'delivered', value: Math.round(kpis.gallonsDelivered.delivered).toString(), variant: 'primary' },
    { label: 'balance', value: `${balance > 0 ? '+' : ''}${Math.round(balance).toString()}`, variant: balance >= 0 ? 'positive' : 'negative' },
  ];
}

function getProgressBarItems(kpis: KPIsInterface) {
  const balance = kpis.gallonsDelivered.balance;
  const plannedQuantity = kpis.gallonsDelivered.planned;
  const total = plannedQuantity + Math.abs(balance);

  return [
    { category: 'delivered', percentage: Utils.getPercentage(kpis.gallonsDelivered.delivered, total) },
    { category: 'planned', percentage: Utils.getPercentage(kpis.gallonsDelivered.planned, total) },
    { category: 'estimated', percentage: Utils.getPercentage(plannedQuantity, total) },
    { category: balance < 0 ? 'negative' : 'positive', percentage: 100 }
  ];
}