import './PeriodSelectList.scss';
import { useEffect, useRef, useState } from "react";
import { DateTime } from 'luxon';
import ArrowIcon from '../arrowIcon/ArrowIcon';
import { PeriodType } from '../../sections/history/Periods';
import PeriodDatePicker from '../periodDatePicker/PeriodDatePicker';
import Config from '../../../services/Config';

export interface PeriodSelectOption {
  from: string;
  to: string;
  label: string;
}

interface PeriodSelectListProps {
  locale: "en" | "nl";
  items: PeriodSelectOption[];
  value: { from: string, to: string };
  periodType: PeriodType;
  onSelect: (from: string, to: string) => void;
}

function PeriodSelectList(props: PeriodSelectListProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function outsideClick(e: any) {
      if (isOpen && node.current && !node.current.contains(e.target)) {
        setIsOpen(!isOpen);
      }
    }
    document.addEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    };
  }, [node, isOpen]);

  function isSelected(item: PeriodSelectOption) {
    return item.from === props.value.from && item.to === props.value.to;
  }

  function getSelectedLabel() {
    const selectedItem = props.items.find(isSelected);
    if (selectedItem !== undefined) {
      return selectedItem.label;
    } else {
      switch (props.periodType) {
        case "day": return props.value.from;
        case "week": return `${props.value.from}/${props.value.to}`;
        case "month": return DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').toFormat('MMMM yyyy');
        case "year": return DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').toFormat('yyyy');
      }
    }
  }

  function isPrevDisabled(): boolean {
    return DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').minus({ days: 1 }) < DateTime.fromFormat(Config.historyMinDate, 'yyyy-MM-dd');
  }

  function isNextDisabled(): boolean {
    if (props.periodType === "day") {
      return DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ days: 2 }) > DateTime.now();
    } else if (props.periodType === "week") {
      return DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ weeks: 1 }).startOf('week') > DateTime.now();
    } else if (props.periodType === "month") {
      return DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ months: 1 }).startOf('month') > DateTime.now();
    } else if (props.periodType === "year") {
      return DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ years: 1 }).startOf('year') > DateTime.now();
    }
    return false;
  }

  const itemClick = (item: PeriodSelectOption) => {
    props.onSelect(item.from, item.to);
    setIsOpen(!isOpen);
  }

  const periodDatePickerClick = (from: string, to: string) => {
    props.onSelect(from, to);
    setIsOpen(!isOpen);
  }

  const periodPrevButtonClick = () => {
    if (props.periodType === "day") {
      const prevDay = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').minus({ days: 1 }).toFormat('yyyy-MM-dd');
      props.onSelect(prevDay, prevDay);
    } else if (props.periodType === "week") {
      const from = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').minus({ weeks: 1 }).startOf('week').toFormat('yyyy-MM-dd');
      const to = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').minus({ weeks: 1 }).endOf('week').toFormat('yyyy-MM-dd');
      props.onSelect(from, to);
    } else if (props.periodType === "month") {
      const from = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').minus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd');
      const to = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').minus({ months: 1 }).endOf('month').toFormat('yyyy-MM-dd');
      props.onSelect(from, to);
    } else if (props.periodType === "year") {
      const from = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').minus({ years: 1 }).startOf('year').toFormat('yyyy-MM-dd');
      const to = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').minus({ years: 1 }).endOf('year').toFormat('yyyy-MM-dd');
      props.onSelect(from, to);
    }
  }

  const periodNextButtonClick = () => {
    if (props.periodType === "day") {
      const nextDay = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ days: 1 }).toFormat('yyyy-MM-dd');
      props.onSelect(nextDay, nextDay);
    } else if (props.periodType === "week") {
      const from = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ weeks: 1 }).startOf('week').toFormat('yyyy-MM-dd');
      const to = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ weeks: 1 }).endOf('week').toFormat('yyyy-MM-dd');
      props.onSelect(from, to);
    } else if (props.periodType === "month") {
      const from = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd');
      const to = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ months: 1 }).endOf('month').toFormat('yyyy-MM-dd');
      props.onSelect(from, to);
    } else if (props.periodType === "year") {
      const from = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ years: 1 }).startOf('year').toFormat('yyyy-MM-dd');
      const to = DateTime.fromFormat(props.value.from, 'yyyy-MM-dd').plus({ years: 1 }).endOf('year').toFormat('yyyy-MM-dd');
      props.onSelect(from, to);
    }
  }

  const arrowDirection = isOpen ? "up" : "down";
  const selectedLabel = getSelectedLabel();

  return <div>
    <button className="period-select-list-nav" onClick={periodPrevButtonClick} disabled={isPrevDisabled()}><ArrowIcon direction="left" /></button>
    <div ref={node} className="period-select-list">
      <button className={`period-select-list-dropdown-btn${isOpen ? ' open' : ''}`} onClick={() => { setIsOpen(!isOpen) }}><label>{selectedLabel}</label><ArrowIcon direction={arrowDirection} /></button>
      {isOpen &&
        <div className="period-select-list-dropdown">
          {
            props.items.map((item, i) => <button className={isSelected(item) ? "selected" : ""} key={`period-select-list-item-${i}`} onClick={() => { itemClick(item); }} >{item.label}</button>)
          }
          <PeriodDatePicker locale={props.locale} value={props.value} periodType={props.periodType} onSelect={periodDatePickerClick} />
        </div>
      }
    </div>
    <button className="period-select-list-nav" onClick={periodNextButtonClick} disabled={isNextDisabled()}><ArrowIcon direction="right" /></button>
  </div>;
}
export default PeriodSelectList;