import { GoalInterface } from '../../../interfaces/GoalInterface';
import CardTitle from '../../layout/cardTitle/CardTitle';
import GoalStatus from '../goalStatus/GoalStatus';
import './GoalHeader.scss';

function GoalHeader(props: { goal: GoalInterface }) {
  return <div className="goal-header">
    <CardTitle title={props.goal.name} />
    <GoalStatus goal={props.goal} />
  </div>;
}
export default GoalHeader;