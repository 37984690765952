import { GoalInterface } from '../../../interfaces/GoalInterface';
import './GoalStatus.scss';

function GoalStatus(props: { goal: GoalInterface }) {
  return <div className={getGoalStatusClassNames(props.goal)}><GoalStatusIcon {...props} /></div>
}

function GoalStatusIcon(props: { goal: GoalInterface }) {
  if (props.goal.started) {
    if (props.goal.successful) {
      return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7474 6.16441C13.1143 5.75159 13.0771 5.11952 12.6644 4.7526C12.2516 4.38568 11.6195 4.42286 11.2526 4.83565L6.6667 9.99481L4.74741 7.83561C4.38049 7.42291 3.74842 7.38571 3.33564 7.75261C2.92285 8.11951 2.88567 8.75161 3.25259 9.16441L5.91926 12.1644C6.109 12.3779 6.381 12.5 6.6667 12.5C6.9523 12.5 7.2243 12.3779 7.4141 12.1644L12.7474 6.16441Z" fill="black" />
      </svg>;
    } else {
      return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.79289 3.79289C4.18342 3.40237 4.81658 3.40237 5.20711 3.79289L8 6.5858L10.7929 3.79289C11.1834 3.40237 11.8166 3.40237 12.2071 3.79289C12.5976 4.18342 12.5976 4.81658 12.2071 5.20711L9.4142 8L12.2071 10.7929C12.5976 11.1834 12.5976 11.8166 12.2071 12.2071C11.8166 12.5976 11.1834 12.5976 10.7929 12.2071L8 9.4142L5.20711 12.2071C4.81658 12.5976 4.18342 12.5976 3.79289 12.2071C3.40237 11.8166 3.40237 11.1834 3.79289 10.7929L6.5858 8L3.79289 5.20711C3.40237 4.81658 3.40237 4.18342 3.79289 3.79289Z" fill="black" />
      </svg>;
    }
  } else {
    return null;
  }
}

function getGoalStatusClassNames(goal: GoalInterface): string {
  if (goal.started) {
    if (goal.completed) {
      if (goal.successful) {
        return "goal-status goal-status-positive";
      } else {
        return "goal-status goal-status-negative";
      }
    } else {
      if (goal.successful) {
        return "goal-status goal-status-outline-positive";
      } else {
        return "goal-status goal-status-outline-negative";
      }
    }
  } else {
    return "goal-status goal-status-outline";
  }
}
export default GoalStatus;