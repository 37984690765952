import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { KPIsInterface } from '@o4c/dip-shared';
import { GoalInterface } from '../../../interfaces/GoalInterface';
import * as Converter from '../../../services/Converter';
import * as Utils from '../../../services/Utils';
import Card from '../../layout/card/Card';
import CompletedRouteTime from '../../shared/completedRouteTime/CompletedRouteTime';
import GallonsDeliveredCompleted from '../../shared/gallonsDeliveredCompleted/GallonsDeliveredCompleted';
import GallonsPerHour from '../../shared/gallonsPerHour/GallonsPerHour';
import GoalsProgress from '../../shared/goalsProgress/GoalsProgress';
import './HistoryDay.scss';

function HistoryDay(props: { kpis: KPIsInterface }) {
  const { t } = useTranslation();
  const goals: GoalInterface[] = Converter.getGoals(props.kpis, t);
  const gphPercentage = Utils.getPercentage(props.kpis.gallonsPerHour.amountPerHour, props.kpis.gallonsPerHour.plannedAmountPerHour);
  const completedRouteTimeChartData = Converter.getCompletedRouteTimeChartData(props.kpis);
  const scheduledStartTime = DateTime.fromISO(props.kpis.scheduledStartTime).toFormat('yyyy-MM-dd HH:mm');

  return <>
    <div className="history-day-header">{scheduledStartTime}</div>
    <Card>
      <GoalsProgress goals={goals} containerClassName="history-goals-progress" />
    </Card>
    <GallonsPerHour goal={goals[0]} percentage={gphPercentage} amountPerHour={props.kpis.gallonsPerHour.amountPerHour} />
    <div className="flex-gap">
      <div className="flex-gap-item">
        <GallonsDeliveredCompleted id={`gallons-delivered-completed-${props.kpis.tripCode}`} goal={goals[1]} planned={props.kpis.gallonsDelivered.planned} delivered={props.kpis.gallonsDelivered.delivered} />
      </div>
      
    </div>
    <div className="history-day-divider"></div>
  </>
}
export default HistoryDay;