import { KPIsInterface } from '@o4c/dip-shared';
import { GoalInterface } from "../../../interfaces/GoalInterface";
import * as Converter from '../../../services/Converter';
import * as Utils from '../../../services/Utils';
import GallonsDelivered from "./gallonsDelivered/GallonsDelivered";
import GallonsPerHour from "../../shared/gallonsPerHour/GallonsPerHour";
import GoalsProgress from "../../shared/goalsProgress/GoalsProgress";
import { useTranslation } from 'react-i18next';
import Greeting from "./greeting/Greeting";
import MainArea from "../../layout/mainArea/MainArea";
import TopArea from "../../layout/topArea/TopArea";
import { filterOutSelfFuel } from '../../../services/Utils';


function TodayInProgress(props: { kpis: KPIsInterface, firstName: string }) {
  const { t } = useTranslation();
  const { kpis } = props;

  const filteredOrders = filterOutSelfFuel(kpis.gallonsDelivered.orders, kpis.truckCode);
  const _kpis: KPIsInterface = {...kpis, gallonsDelivered: { ...kpis.gallonsDelivered, orders: filteredOrders}}

  const goals: GoalInterface[] = Converter.getGoals(_kpis, t);
  const gphPercentage = Utils.getPercentage(_kpis.gallonsPerHour.amountPerHour, _kpis.gallonsPerHour.plannedAmountPerHour);
  const gallonsDeliveredData = Converter.getGallonsDeliveredData(_kpis);
  const hoursToCompleteRouteData = Converter.getHoursToCompleteRouteData(_kpis, t);
  const greeting = Utils.getGreeting(t);

  
  

  return <>
    <TopArea>
      <div className="today-top-area">
        <Greeting name={props.firstName} greeting={greeting} />
        <GoalsProgress goals={goals} containerClassName="today-goals-progress" />
      </div>
    </TopArea>
    <MainArea>
      <GallonsPerHour goal={goals[0]} percentage={Utils.roundToTenth(gphPercentage)} amountPerHour={props.kpis.gallonsPerHour.amountPerHour} />
      <GallonsDelivered goal={goals[1]} balanceRows={gallonsDeliveredData.balanceRows} totalRows={gallonsDeliveredData.totalRows} progressBarItems={gallonsDeliveredData.progressBarItems} orders={gallonsDeliveredData.orders} />
    </MainArea>
  </>;
}
export default TodayInProgress;