import { useTranslation } from 'react-i18next';
import { GoalInterface } from '../../../interfaces/GoalInterface';
import './GoalsProgress.scss';

function GoalsProgress(props: { goals: GoalInterface[], containerClassName: string }) {
  const { t } = useTranslation();

  //Filtering out the last goal because it is excluded from UI.
  const goalsSubset = props.goals.slice(0, 2);
  const successfulGoals = goalsSubset.filter((o) => o.successful).length;
  return <div className={props.containerClassName}>
    <div className="goals-title">{t('titles.goals')}: {successfulGoals}/{goalsSubset.length}</div>
    <div className="goals-container">
      {
        goalsSubset.map((item, i) => <div key={`goal-item-${i}`} className={getGoalClassNames(item)}></div>)
      }
    </div>
  </div>;
}

function getGoalClassNames(goal: GoalInterface): string {
  if (goal.started) {
    if (goal.completed) {
      if (goal.successful) {
        return "goal goal-positive";
      } else {
        return "goal goal-negative";
      }
    } else {
      if (goal.successful) {
        return "goal goal-outline-positive";
      } else {
        return "goal goal-outline-negative";
      }
    }
  } else {
    return "goal goal-outline";
  }
}

export default GoalsProgress;