import { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Settings } from 'luxon';
import * as Theme from './services/Theme';
import * as Api from './services/Api';
import Tabs from './components/layout/tabs/Tabs';
import Today from './components/sections/today/Today';
import History from './components/sections/history/History';
import Message from './components/layout/message/Message';
import './App.scss';

function App() {
  let location = useLocation();
  let query = new URLSearchParams(location.search);
  const { t, i18n } = useTranslation();
  const lang = (query.get("lang") || "en") as "en" | "nl";
  const token = query.get("token");
  const magazineId = query.get("magazine");
  const [magazine, setMagazine] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    async function loadMagazine() {
      const msg = t('messages.invalidToken');
      if (token && magazineId) {
        try {
          const {data} = await Api.getMagazine(token, magazineId);
          setMagazine(data);
          setError(false);
          setErrorMessage("");
        } catch (err) {
          console.error(err);
          setError(true);
          setErrorMessage(msg);
        }
      } else {
        setError(true);
        setErrorMessage(msg);
      }
    }
    loadMagazine();
    i18n.changeLanguage(lang);
    Settings.defaultLocale = lang;
  }, [token, magazineId]);

  useEffect(() => {
    const themeId = magazine?.themeId || 'ortec';
    const currentTheme = Theme.get(themeId);
    Theme.loadVariables(currentTheme);
  }, [magazine]);

  const navLinks = [
    { to: `/today?${query.toString()}`, title: t('tabs.today') },
    { to: `/history?${query.toString()}`, title: t('tabs.history') }
  ];

  if (!error) {
    return <>
      <Tabs items={navLinks} />
      <Switch>
        <Redirect exact from="/" to={`/today?${query.toString()}`} />
        <Route path="/today"><Today /></Route>
        <Route path="/history"><History /></Route>
      </Switch>
    </>;
  } else {
    return <Message type="error" text={errorMessage} />
  }
}

export default App;
